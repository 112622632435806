import LocalFileSystem from '@/filesystem/local.js';


function filesystem (type, basedir) {
  if (type == 'local') {
    return new LocalFileSystem(basedir);
  }

  throw new Error("Unknown filesystem type");
}

export default filesystem;
