<template>
  <q-dialog ref="dialogRef" @hide="onDialogCancel" transition-duration="100" full-width full-height>
    <q-card class="q-dialog-plugin">
      <q-toolbar>
        <q-toolbar-title>
          <span v-if="loading">Loading pubcrank.yml</span>
          <span v-else>Edit {{ fs.basedir.name }}/pubcrank.yml</span>
        </q-toolbar-title>
        <q-btn color="negative" flat icon="mdi-close" @click="onDialogCancel" />
      </q-toolbar>
      <q-card-section>
        <div class="wrapper" ref="codemirrorEditor"></div>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn color="primary" label="Save & Reload" @click="onOKClick" />
        <q-btn color="accent" label="Cancel" @click="onDialogCancel" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
<script>
import { ref, watch, nextTick } from 'vue';
import { useDialogPluginComponent } from 'quasar';

import Codemirror from 'codemirror';
import yaml from 'js-yaml';

export default {
  emits: [...useDialogPluginComponent.emits],
  props: ['fs'],
  setup(props) {
    const { dialogRef, onDialogOK, onDialogCancel } = useDialogPluginComponent();
    var codemirrorEditor = ref(null);
    var loading = ref(true);
    var codemirrorInstance;

    watch(codemirrorEditor, (newRef, oldRef) => {
      if (newRef && oldRef === null) {
        setTimeout(() => {
          codemirrorInstance = new Codemirror(codemirrorEditor.value, {
            lineNumbers: true,
            styleActiveLine: true,
            autoCloseTags: true,
            matchBrackets: true,
            indentWithTabs: false,
            smartIndent: true,
            autoCloseBrackets: true,
            tabSize: 2,
            indentUnit: 2,
            value: "",
            mode: 'yaml',
            lineWrapping: true,
            extraKeys: {
              "Tab": function(cm) {
                var spaces = Array(cm.getOption("indentUnit") + 1).join(" ");
                cm.replaceSelection(spaces, "end", "+input");
              }
            }
          });

          codemirrorInstance.setSize("100%", codemirrorEditor.value.offsetHeight + "px");

          nextTick(() => {
            codemirrorInstance.focus();

            props.fs.read_config()
              .then((config) => {
                codemirrorInstance.setValue(config);
                loading.value = false;
              })
              .catch((e) => {
                console.error(e);
                alert("Error loading pubcrank.yml");
                onDialogCancel();
              });
          });
        }, 200);
      }
    });

    function onOKClick () {
      var text = codemirrorInstance.getValue();
      try {
        var obj = yaml.load(text);
      } catch (e) {
        console.log(e);
        alert("Invalid YAML");
        return;
      }

      props.fs.save_config(text, false)
        .then(() => {
          onDialogOK(text);
        })
        .catch((e) => {
          console.error(e);
          alert("Error saving pubcrank.yml");
        });
    }

    return {dialogRef, onOKClick, onDialogCancel, codemirrorEditor, loading};
  }
};
</script>
<style scoped>
.q-card {
  display: flex;
  flex-direction: column;
}

.q-card__section {
  flex-grow: 1;
}

.wrapper {
  height: 100%;
}
</style>
