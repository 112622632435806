<template>
  <q-layout view="hHh lpR fFf" v-if="compatible_browser">
    <q-header elevated class="bg-primary text-white">
      <q-toolbar>
        <q-toolbar-title>
          <a href="/">
            <q-avatar>
              <img src="/icons/inverted.png">
            </q-avatar>
            <span class="black-ops">PubCrank</span>
          </a>
        </q-toolbar-title>
        <q-btn flat icon="mdi-help-rhombus-outline" href="/docs/"></q-btn>
        <span @click.middle="sentry_test">{{ version }}</span>
      </q-toolbar>
    </q-header>
    <q-drawer v-model="drawer_open" side="left" bordered>
      <side-panel></side-panel>
    </q-drawer>
    <q-page-container class="main-container">
      <tabs-panel></tabs-panel>
    </q-page-container>
  </q-layout>
  <div class="not-compatible" v-else>
    <h2>This browser is not compatible with the PubCrank editor.</h2>
    <p>
      Please use Chrome, MS Edge, or Opera.
      <br><br>
      Brave is compatible with the <code>brave://flags/#file-system-access-api</code> flag enabled.
    </p>
  </div>
</template>
<style scoped>
.main-container {
  height: 100vh;
}

img {
  height: 32px !important;
  width: 32px !important;
  padding-right: 3px;
}

</style>
<script>
import { ref } from 'vue';
import { storeToRefs } from 'pinia';

import useMainStore from '@/main_store.js';

import SidePanel from '@/editor/editor_side.vue';
import TabsPanel from '@/editor/editor_tabs.vue';
import VERSION from '@/version.js';

export default {
  components: {SidePanel, TabsPanel},
  setup () {
    document.title = "PubCrank Editor";
    var { dir_handle, site_config } = storeToRefs(useMainStore());

    const drawer_open = true;
    var compatible_browser = true;

    if (!window.showDirectoryPicker) {
      compatible_browser = false;
    }

    function sentry_test(e) {
      myUndefinedFunction();
    }

    return {
      compatible_browser,
      drawer_open,
      sentry_test,
      version: VERSION,
    }
  }
};
</script>
