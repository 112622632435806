import { defineStore } from 'pinia';


var useMainStore = defineStore({
  id: 'main',
  state: () => ({
    fs: null,
    content_dirs: [],
    files: [],
    current_tab: null,
  }),
  actions: {
    close_site() {
      this.fs = null;
      this.content_dirs.splice(0, this.content_dirs.length);
      this.files.splice(0, this.files.length);
      this.current_tab = null;
    },
    async set_filesystem(fs) {
      this.close_site();

      try {
        await fs.find_config();
      } catch (e) {
        var initialized = await fs.init_config();
        if (!initialized) {
          return;
        }
      }

      try {
        await fs.load_config();
      } catch (e) {
        console.error(e);
        alert('Invalid Config: pubcrank.yml');
        return;
      }

      this.fs = fs;
      this.content_dirs = await fs.get_contents();
    },
    async open_file(path) {
      for (var i=0; i < this.files.length; i++) {
        if (path == this.files[i].path) {
          this.current_tab = this.files[i].path;
          return;
        }
      }

      try {
        var file = await this.fs.open(path);
      } catch (e) {
        console.error(e);
        alert('Can not open file.');
      }

      this.files.push(file);
      this.current_tab = file.path;
    },
    close_file(index) {
      this.files.splice(index, 1);
    },
    select_next_tab(index) {
      for (var i=0; i < this.files.length; i++) {
        if (this.current_tab == this.files[i].path) {
          return;
        }
      }

      if (this.files.length == 0) {
        this.current_tab = null;
      } else {
        if (index == this.files.length) {
          index = index - 1;
        }

        this.current_tab = this.files[index].path;
      }
    }
  }
});

export default useMainStore;
