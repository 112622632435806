<template>
  <div class="wrapper" ref="codemirrorEditor"></div>
</template>
<script>
import { ref, onMounted } from 'vue';

import Codemirror from 'codemirror';

export default {
  props: ['file'],
  emits: ['changed', 'save'],
  setup(props, { emit }) {
    var codemirrorEditor = ref(null);
    var codemirrorInstance;

    onMounted(() => {
      codemirrorInstance = new Codemirror(codemirrorEditor.value, {
        lineNumbers: true,
        styleActiveLine: true,
        autoCloseTags: true,
        matchBrackets: true,
        indentWithTabs: false,
        smartIndent: true,
        autoCloseBrackets: true,
        tabSize: 2,
        indentUnit: 2,
        value: props.file.text,
        mode: 'yaml-frontmatter',
        lineWrapping: true,
        extraKeys: {
          "Tab": function(cm) {
            var spaces = Array(cm.getOption("indentUnit") + 1).join(" ");
            cm.replaceSelection(spaces, "end", "+input");
          },
          "Ctrl-S": function (cm) {
            emit('save');
          }
        }
      });

      codemirrorInstance.setSize("100%", "100%");

      codemirrorInstance.on('change', () => {
        let val = codemirrorInstance.getValue();
        emit('changed', val);
      });
    });

    function save (event) {
      emit('save');
    }
    return {codemirrorEditor, save};
  }
};
</script>
<style scoped>
.wrapper {
  border: 1px solid rgba(0, 0, 0, .24);
  border-radius: 4px;
  padding: 1px;
  height: 100%;
}
</style>
