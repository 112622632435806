import { createRouter, createWebHistory } from 'vue-router';

import Home from '@/home/Home.vue';
import Editor from '@/editor/Editor.vue';
import Docs from '@/docs/Docs.vue';

const routes = [
  { path: '/', component: Home },
  { path: '/editor/', component: Editor },
  { path: '/docs/', component: Docs },
  { path: '/docs/:page+', component: Docs },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
