// https://webreflection.medium.com/using-the-input-datetime-local-9503e7efdce
export function monkey_patch_date () {
  if (Date.prototype.toDatetimeLocal) {
    return;
  }

  Date.prototype.toDatetimeLocal =
    function toDatetimeLocal() {
      var
        date = this,
        ten = function (i) {
          return (i < 10 ? '0' : '') + i;
        },
        YYYY = date.getFullYear(),
        MM = ten(date.getMonth() + 1),
        DD = ten(date.getDate()),
        HH = ten(date.getHours()),
        II = ten(date.getMinutes()),
        SS = ten(date.getSeconds())
      ;
      return YYYY + '-' + MM + '-' + DD + 'T' +
               HH + ':' + II + ':' + SS;
    };
}
