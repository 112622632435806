import axios from 'axios';
import { Notify, Dialog } from 'quasar';

import CrankSpec from '@/crank_spec.js';
import mime from '@/mimes/index.js';
import { capture_error } from '@/utils/logging.js';

export class FileSystem {
  features = 'all';

  constructor(basedir) {
    this.basedir = basedir;
    this.content_dirs = [];
    this.spec = CrankSpec;
  }

  has_feature(feature) {
    if (this.features === 'all') {
      return true;
    } else if (this.features.indexOf(feature) !== -1) {
      return true;
    }

    return false;
  }

  parse_config(str) {
    this.config_obj = this.spec.parse_config(str);
    this.config_spec = new this.spec(this.config_obj);
  }

  async load_config() {
    var config_str = await this.read_config();
    this.parse_config(config_str);
  }

  init_config() {
    return new Promise((resolve, reject) => {
      Dialog.create({
        title: "Site Configuration Error",
        message: "No pubcrank.yml found. Would you like to create one?",
        cancel: true,
        persistent: true,
        options: {
          type: 'radio',
          model: false,
          items: [
            { label: 'No, I\'ll create manually', value: false, color: 'secondary' },
            { label: 'Use Astro Default', value: 'astro' },
            { label: 'Use Eleventy Default (11ty)', value: 'eleventy' },
            { label: 'Use Hugo Default', value: 'hugo' },
            { label: 'Use Jekyll Default', value: 'jekyll' }
          ]
        }
      })
        .onOk(data => {
          if (data) {
            this.create_config(data)
              .then(() => {
                resolve(data);
              })
              .catch((e) => {
                console.error(e);
                alert("Error creating pubcrank.yml");
                resolve(false);
              });
          } else {
            resolve(false);
          }
        }).onCancel(() => {
          resolve(false);
        });
    });
  }

  async add({name, fileType, parent, body}) {
    body = body || "";

    if (fileType == 'file') {
      await this.save(`${parent.id}/${name}`, body, true);
    } else {
      await this.create_dir(`${parent.id}/${name}`);
    }
  }

  async create_config(ctype) {
    var response = await axios.get(`/starter-settings/${ctype}.yml`);
    await this.save_config(response.data, true);
    await this.find_config();
  }

  async save_config(text, create) {
    await this.save(this.spec.config_file, text, create);
  }

  async get_contents() {
    var ret = [
      {label: this.basedir.name, id: "<title>", header: 'title'},
      {label: "pubcrank.yml", id: "<settings>", icon: 'mdi-cog', header: 'settings', fs: this}
    ];

    for (var i=0; i < this.config_obj['editable'].length; i++) {
      var e = this.config_obj['editable'][i];
      var r = {label: e || '<root>', id: e || "<root>", icon: 'mdi-application-edit', lazy: true};
      ret.push(r);
    }

    return ret;
  }

  async list_static_dir(path, parent, file_type) {
    if (path) {
      return await this.list_dir(path, parent, file_type);
    } else {
      var ret = [];
      var paths = this.config_spec.static_paths();

      function translate_func(index) {
        return (p) => {
          p = p.replace("<root>/", "");
          return p.replace(paths[index][0], paths[index][1]);
        }
      }

      for (var i=0; i < paths.length; i++) {
        var root_parent = {
          path: '',
          handle: this.basedir,
          translator: translate_func(i)
        };

        ret.push({
          label: paths[i][0] || '<root>',
          icon: 'mdi-folder',
          id: paths[i][0] || '<root>',
          lazy: true,
          translator: translate_func(i),
          parent: root_parent
        });
      }

      return ret;
    }
  }

  is_file_type(file, ftype) {
    if (ftype) {
      let parts = mime.lookup(file).split("/");
      return parts[0] == ftype || parts[1] == ftype;
    }

    return true;
  }

  filesort(a, b) {
    a = a.label.toLowerCase();
    b = b.label.toLowerCase();

    if (a < b) {
      return -1;
    } else if (a > b) {
      return 1;
    }

    return 0;
  }

  async list_dir(path, parent, file_type, create) {
    var ret = await this._list_dir(path, parent, file_type, create);
    var dirs = ret[0].sort(this.filesort);
    var files = ret[1].sort(this.filesort);
    return dirs.concat(files);
  }
}

export class File {
  constructor(basedir, path, filesystem) {
    this.basedir = basedir;
    this.path = path;
    this.filesystem = filesystem;
    this.parsed = null;
    this.split = 33;
    this.changed = false;
    this.editor = 'visual';
  }

  get name() {
    var paths = this.path.split("/");
    return paths[paths.length - 1];
  }

  save_content = async (text, html) => {
    this.parsed.content = text;
    await this.save();
  }

  update_parsed_data(text) {
    try {
      this.parsed = {...this.filesystem.spec.parse_content_file(text)};
    } catch (e) {
      console.error(e);
    }
  }

  async raw_save(text) {
    var dismiss = Notify.create({
      type: 'info',
      position: 'top',
      message: `Saving file ${this.name} ...`
    });

    await this.filesystem.save(this.path, text);
    this.update_parsed_data(text);
    setTimeout(dismiss, 500);
  }

  dump_text() {
    for (let [key, value] of Object.entries(this.get_fields())) {
      if (value.type && value.value && value.type.toLowerCase() == 'readonly') {
        this.parsed.data[key] = value.value;
      }
    }

    this.text = this.filesystem.spec.generate_markdown(this.parsed.content, this.parsed.data);
    return this.text;
  }

  async save() {
    var dismiss = Notify.create({
      type: 'info',
      position: 'top',
      message: `Saving file ${this.name} ...`
    });

    this.dump_text();

    try {
      await this.filesystem.save(this.path, this.text);
      setTimeout(dismiss, 500);
    } catch (e) {
      dismiss();
      capture_error(e);
      Notify.create({
        type: 'negative',
        position: 'top',
        message: `Error saving ${this.name} ...`
      });
    }
  }

  get_fields() {
    return this.filesystem.config_spec.get_fields(this.parsed.data);
  }

  get content_allowed() {
    return this.filesystem.config_spec.content_allowed(this.parsed.data);
  }

  get template() {
    return this.filesystem.config_spec.get_template(this.parsed.data);
  }

  set template(value) {
    this.filesystem.config_spec.set_template(this.parsed.data, value);
  }
}
