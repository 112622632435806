<template>
<div class="EditorTabsPanel">
  <q-tabs align="left" outside-arrows v-model="current_tab">
    <q-tab :name="file.path" v-for="(file, idx) in files" :key="file.path">
      <span>
        <strong><span v-if="file.changed">*</span>{{file.name}}</strong>
        <q-btn icon="mdi-close" color="negative" round flat size="xs" @click.stop="close(idx)" title="close"></q-btn>
      </span>
    </q-tab>
  </q-tabs>
  <q-tab-panels v-model="current_tab" animated>
    <q-tab-panel :name="file.path" v-for="file in files" :key="file.path">
      <div class="file-header">
        <div>
          <div>
            <q-select
              v-if="file.editor == 'visual'"
              outlined
              dense
              v-model="file.template"
              :options="file.filesystem.config_spec.templates"
              @update:model-value="(value) => file_changed(file)"
              label="Template"
            />
            <strong class="editor-title" v-else>Code Editor</strong>
          </div>
          <div>
            <q-btn color="primary" size="sm" @click="() => do_save(file)">Save Changes</q-btn>
          </div>
          <div>
            <q-btn
              v-if="file.editor == 'code'"
              icon="mdi-list-box-outline"
              flat
              color="secondary"
              title="Visual Editor"
              @click="switch_editor(file, 'visual')"></q-btn>
            <q-btn
              v-if="file.editor == 'visual'"
              icon="mdi-file-code-outline"
              flat
              color="secondary"
              title="Code Editor"
              @click="switch_editor(file, 'code')"></q-btn>
          </div>
        </div>
      </div>
      <div v-if="file.editor == 'code'" class="no-split">
        <code-editor
          @changed="(text) => file_rawchanged(file, text)"
          @save="() => save_rawfile(file)"
          :file="file"></code-editor>
      </div>
      <q-splitter horizontal v-model="file.split" v-else-if="file.content_allowed">
        <template v-slot:before>
          <fmatter-editor @changed="() => file_changed(file)" :file="file"></fmatter-editor>
        </template>
        <template v-slot:after>
          <markdown-editor
            @changed="() => file_changed(file)"
            @save="(text) => save_file(file, text)"
            :file="file"></markdown-editor>
        </template>
      </q-splitter>
      <div v-else class="no-split">
        <fmatter-editor @changed="() => file_changed(file)" :file="file"></fmatter-editor>
      </div>
    </q-tab-panel>
  </q-tab-panels>
</div>
</template>
<script>
import { storeToRefs } from 'pinia';

import useMainStore from '@/main_store.js';
import FmatterEditor from '@/editor/fmatter_editor.vue';
import MarkdownEditor from '@/editor/markdown_editor.vue';
import CodeEditor from '@/editor/code_editor.vue';
import { error_alert } from '@/utils/logging.js';

export default {
  components: {FmatterEditor, MarkdownEditor, CodeEditor},
  setup() {
    var store = useMainStore();
    var { files, current_tab} = storeToRefs(store);

    async function close(index) {
      var confirmed = true;
      if (files.value[index].changed.value) {
        confirmed = confirm('Are you sure you wish to close with unsaved changes?')
      }

      if (confirmed) {
        store.close_file(index);
        store.select_next_tab(index);
      }
    }

    function file_changed (file) {
      file.dump_text();
      file.changed = true;
    }

    function file_rawchanged (file, text) {
      file.text = text;
      file.update_parsed_data(text);
      file.changed = true;
    }

    function save_file (file, text) {
      var p;

      if (text) {
        p = file.save_content(text);
      } else {
        p = file.save();
      }

      p.then(() => {
          file.changed = false;
        })
        .catch(error_alert("Error saving file."));
    }

    function do_save (file) {
      if (file.editor == 'code') {
        save_rawfile(file)
      } else {
        save_file(file);
      }
    }

    function save_rawfile(file) {
      file.raw_save(file.text)
        .then(() => {
          file.changed = false;
        })
        .catch(error_alert("Error saving file."));
    }

    function switch_editor(file, etype) {
      file.editor = etype;
    }

    return {
      files,
      current_tab,
      close,
      store,
      file_changed,
      file_rawchanged,
      save_file,
      save_rawfile,
      do_save,
      switch_editor
    };
  }
}
</script>
<style scoped>
.EditorTabsPanel {
  height: 100%;

  .file-header > div {
    display: flex;
    align-items: center;

    & > div {
      margin: 5px;
    }

    & > div:first-child {
      width: 200px;
    }
  }

  .editor-title {
    display: block;
    text-align: center;
  }

  .q-tab-panel {
    padding: 8px 2px 0px 2px;
  }

  .q-tab-panels {
    height: calc(100% - 48px);
  }

  .q-splitter {
    height: calc(100% - 50px);
  }

  .v-md-editor {
    height: 100%;
  }

  .no-split {
    height: calc(100% - 50px);
    overflow: auto;
  }
}
</style>
