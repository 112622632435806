<template>
  <label>{{ label }}</label>
  <div class="text-negative error" v-if="error">Invalid YAML</div>
  <div class="wrapper" ref="codemirrorEditor"></div>
</template>
<script>
import { ref, onMounted } from 'vue';
import Codemirror from 'codemirror';
import yaml from 'js-yaml';

export default {
  props: ['initial', 'label'],
  emits: ['change'],
  setup(props, { emit }) {
    var codemirrorEditor = ref(null);
    var text = "null";
    var error = ref(false);
    if (props.initial !== undefined) {
      text = yaml.dump(props.initial);
    }

    onMounted(() => {
      var codemirrorInstance = new Codemirror(codemirrorEditor.value, {
        lineNumbers: true,
        styleActiveLine: true,
        autoCloseTags: true,
        matchBrackets: true,
        indentWithTabs: false,
        smartIndent: true,
        autoCloseBrackets: true,
        tabSize: 2,
        indentUnit: 2,
        value: text,
        mode: 'yaml',
        lineWrapping: true,
        extraKeys: {
          "Tab": function(cm) {
            var spaces = Array(cm.getOption("indentUnit") + 1).join(" ");
            cm.replaceSelection(spaces, "end", "+input");
          }
        }
      });

      codemirrorInstance.on('change', () => {
        let val = codemirrorInstance.getValue();
        try {
          val = yaml.load(val);
        } catch(e) {
          error.value = true;
          return;
        }

        error.value = false;
        emit('change', val);
      });
    });

    return {codemirrorEditor, error};
  }
};
</script>
<style scoped>
.wrapper {
  border: 1px solid rgba(0, 0, 0, .24);
  border-radius: 4px;
  padding: 1px;
}

label {
  color: rgba(0,0,0,.6);
}
</style>
