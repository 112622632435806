import * as Sentry from "@sentry/vue";

export function capture_error (err) {
  if (import.meta.env.PROD) {
    Sentry.captureException(err);
  }

  console.error(err);
}

export function error_alert(message) {
  return (e) => {
    console.error(e);
    alert(message);
  }
}
