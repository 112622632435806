<template>
  <div>
    <router-view />
  </div>
</template>
<style>
.tc {
  text-align: center;
}

.not-compatible {
  margin: 20px;

  h2 {
    font-size: 1.8rem;
  }

  p {
    font-size: 1.4rem;
  }
}

header.bg-primary .q-toolbar a {
  color: #fff;
  text-decoration: none;
}

header.bg-primary .q-toolbar .q-btn {
  color: #fff !important;
}


h1 {
  font-size: 2.4rem;
  line-height: normal;
  margin: 10px 0;
  font-weight: bold;
}

h2 {
  font-size: 2.0rem;
  line-height: normal;
  margin: 10px 0;
  font-weight: bold;
}

h3 {
  font-size: 1.6rem;
  line-height: normal;
  margin: 10px 0;
  font-weight: bold;
}

.v-md-editor .CodeMirror {
  font-size: 17px !important;
  line-height: 1.3 !important;
}

.CodeMirror pre {
  word-break: break-word !important;
}
</style>
<script>
export default {
  setup() {
    return {};
  },
};
</script>
