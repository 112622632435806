import yaml from 'js-yaml';
import fmatter from 'front-matter';
import deepcopy from 'deepcopy';
import nunjucks from 'nunjucks';

class CrankSpec {
  static config_file = 'pubcrank.yml';

  constructor(config_obj) {
    this.config = config_obj;
  }

  static parse_config(str) {
    return yaml.load(str);
  }

  static parse_content_file(str) {
    let parsed = fmatter(str);
    return {content: parsed.body, data: parsed.attributes};
  }

  static generate_markdown(content, data) {
    return '---\n' + yaml.dump(data) + '---\n' + content;
  }

  get_fields(data) {
    var template = this.get_template(data);
    if (this.config.templates[template]) {
      return deepcopy(this.config.templates[template].fields);
    }

    return {};
  }

  get_template(data) {
    if (!data[this.config.template_field]) {
      data[this.config.template_field] = this.config.template_default;
    }

    return data[this.config.template_field];
  }

  set_template(data, value) {
    data[this.config.template_field] = value;
  }

  get templates() {
    return Object.keys(this.config.templates);
  }

  get image_config() {
    let idefault = {
      allow_tags: true,
      allow_size: true,
      md_template: '![{{desc}}]({{path}}){% if width or height %}{% raw %}{{{{% endraw %}{% if width %}width="{{ width }}"{% if height %} {% endif %}{% endif %}{% if height %}height="{{ height }}"{% endif %}{% raw %}}}}{% endraw %}{% endif %}',
      tag_template: '<img src="{{path}}" alt="{{desc}}" style="{% if width %}width: {{ width }}{% if height %}; {% endif %}{% endif %}{% if height %}height: {{ height }};{% endif %}">'
    };
    return this.config.images || idefault;
  }

  image_code(context) {
    if (context.format == 'md') {
      return nunjucks.renderString(this.image_config.md_template, context);
    }

    return nunjucks.renderString(this.image_config.tag_template, context);
  }

  content_allowed(data) {
    var template = data[this.config.template_field] || this.config.template_default;

    if (this.config.templates[template]) {
      if (this.config.templates[template].content == undefined) {
        return true;
      }

      return this.config.templates[template].content;
    }

    return true;
  }

  static_paths() {
    return this.config.uploads.map((path) => {
      return [path.dir, path.url];
    });
  }
}

export default CrankSpec;
