<template>
<div class="EditorSidePanel">
  <div v-if="fs">
    <q-tree
      dense
      :nodes="content_dirs"
      node-key="id"
      :expanded="expanded"
      @lazy-load="onLazyLoad"
      @update:expanded="onExpand"
      ref="tree"
    >
      <template v-slot:header-file="prop">
        <div class="file-slot">
          <q-btn
            class="file btn-left"
            align="left"
            no-wrap
            flat
            size="md"
            padding="xs"
            @click="selected(prop.node.id)"
            :title="prop.node.label"
          >
            {{ short_name(prop.node.label) }}
          </q-btn>
          <q-btn dense icon="mdi-dots-vertical" class="shady" flat @click.stop="(e) => {}">
            <q-menu>
              <q-list dense bordered separator>
                <q-item v-if="fs.has_feature('rename-file')"
                  dense
                  clickable
                  v-close-popup @click="(e) => rename_file(e, prop.node)"
                >
                  <q-item-section avatar>
                    <q-icon name="mdi-rename" />
                  </q-item-section>
                  <q-item-section>
                    Rename
                  </q-item-section>
                </q-item>
                <q-item v-else dense>
                  <q-item-section avatar>
                    <q-icon name="mdi-alert-circle-outline" color="warning"/>
                  </q-item-section>
                  <q-item-section>
                    Disabled for Local file system
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </div>
      </template>
      <template v-slot:header-title="prop">
        <strong class="title">{{ prop.node.label }}</strong>
        <q-btn no-wrap flat icon="mdi-close" size="md" padding="xs" color="negative" @click="close_site(prop.node.label)"></q-btn>
      </template>
      <template v-slot:header-settings="prop">
        <q-btn class="file bnt-left" align="left" no-wrap flat size="md" padding="xs" @click="(e) => edit_settings(prop.node)">
          <q-icon :name="prop.node.icon" class="q-mr-sm" />
          {{ prop.node.label }}
        </q-btn>
      </template>
      <template v-slot:default-header="prop">
        <q-icon :name="prop.node.icon" class="q-mr-sm" />
        <div>{{ prop.node.label }}</div>
        <q-space/>
        <q-btn dense icon="mdi-folder-settings-outline" flat @click.stop="(e) => {}">
          <q-menu>
            <q-list dense bordered separator>
              <q-item dense clickable v-close-popup @click="(e) => add_file(e, prop.node, 'file')">
                <q-item-section avatar>
                  <q-icon name="mdi-file-document-plus-outline" />
                </q-item-section>
                <q-item-section>
                  Add File
                </q-item-section>
              </q-item>
              <q-item dense clickable v-close-popup @click="(e) => add_file(e, prop.node, 'dir')">
                <q-item-section avatar>
                  <q-icon name="mdi-folder-plus-outline" />
                </q-item-section>
                <q-item-section>
                  Add Folder
                </q-item-section>
              </q-item>
              <q-item clickable v-close-popup @click="(e) => refresh(e, prop.node)">
                <q-item-section avatar>
                  <q-icon name="mdi-refresh" />
                </q-item-section>
                <q-item-section>
                  Refresh
                </q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>
      </template>
    </q-tree>
  </div>
  <div class="tc q-pa-md" v-else>
    <q-btn color="primary" @click="pick_directory()">Open Local Site</q-btn>
  </div>
</div>
</template>
<style scoped>
.q-tree button.file {
  text-transform: none;
  width: 100%;
}

.q-item__section--avatar {
  min-width: unset;
}

.shady {
  color: rgba(29, 29, 29, 0.4);
}

.file-slot {
  display: flex;
  flex-grow: 1;
}

.file-slot:hover {
  background-color: rgba(150, 150, 150, 0.1);
}

strong.title {
  display: block;
  text-align: center;
  width: 100%;
}
</style>
<script>
import { ref, nextTick } from 'vue';
import { Dialog } from 'quasar';
import { storeToRefs } from 'pinia';

import useMainStore from '@/main_store.js';
import filesystem from '@/filesystem/index.js';

import AddFile from '@/dialogs/add-file.vue';
import Settings from '@/dialogs/settings.vue';

import { error_alert } from '@/utils/logging.js';

export default {
  setup () {
    var { fs, content_dirs } = storeToRefs(useMainStore());
    var store = useMainStore();

    async function set_fs(fs) {
      await store.set_filesystem(fs);
      if (store.fs) {
        fs.config_obj.editable.forEach((e) => {
          tree.value.setExpanded(e || "<root>", true);
        });
      }
    }

    async function pick_directory () {
      try {
        var dh = await window.showDirectoryPicker();
      } catch (e) {
        // didn't pick anything
        console.error(e);
        return;
      }

      if (dh) {
        var fs = filesystem('local', dh);
        await set_fs(fs);
      }
    }

    async function selected(path) {
      await store.open_file(path);
    }

    function onLazyLoad({ node, key, done, fail }) {
      var p;

      if (node.parent) {
        p = node.parent.filesystem.list_dir(node.label, node.parent);
      } else {
        let root_fs = fs.value;
        p = root_fs.list_dir(
          node.label, {path: "", handle: root_fs.basedir, filesystem: root_fs}, 'markdown', true);
      }

      p.then((files) => {
          done(files);
        })
        .catch((e) => {
          console.error(e);
          fail();
        });
    }

    function onExpand(e) {
      expanded.value = e;
    }

    function add_file(e, node, fileType) {
      Dialog.create({
        component: AddFile,
        componentProps: {node, fileType}
      })
        .onOk((data) => {
          fs.value.add({...data, parent: node})
            .then(() => {
              store.open_file(node.id + "/" + data.name)
                .then(() => {})
                .catch(error_alert("Error opening new file."));
              refresh(e, node);
            })
            .catch((e) =>  {
              console.error(e);
              alert(`Error creating ${data.name}`);
            });
        })
        .onCancel(() => {});
    }

    function refresh(e, node) {
      delete node.children;
      tree.value.setExpanded(node.id, false);

      nextTick(() => {
        tree.value.setExpanded(node.id, true);
      });
    }

    function rename_file (e, node) {
      Dialog.create({
        title: 'Rename: ' + node.id,
        prompt: {
          model: node.label + "",
          type: 'text'
        },
        cancel: true,
        persistent: true
      }).onOk(file_name => {
        if (file_name && file_name != node.label) {
          fs.value.rename(node.id, file_name)
            .then(() => {
              //refresh(e, node.parent);
              let parts = node.id.split("/");
              parts = parts.slice(0, -1);
              let parent = parts.join("/");
              let pnode = tree.value.getNodeByKey(parent);
              if (pnode) {
                refresh(e, pnode);
              }
            })
            .catch(error_alert("Error renaming file."));
        }
      })
    }

    function edit_settings (node) {
      Dialog.create({
        component: Settings,
        componentProps: {fs: node.fs}
      })
        .onOk((data) => {
          var new_fs = filesystem('local', fs.value.basedir);
          set_fs(new_fs)
            .then(() => {})
            .catch((e) => {
              console.log(e);
              alert("Error reloading filesystem.")
            });

        })
        .onCancel(() => {});
    }

    function close_site(label) {
      Dialog.create({
        title: `Confirm Close - ${label}`,
        message: 'Are you sure you wish to close this site?',
        cancel: true,
        persistent: true
      }).onOk(() => {
        store.close_site();
      });
    }

    function short_name (name) {
      if (name.length > 26) {
        return name.slice(0, 20) + "…" + name.slice(name.length - 7);
      }

      return name;
    }

    var expanded = ref([]);
    var tree = ref(null);
    return {
      fs,
      content_dirs,
      pick_directory,
      expanded,
      selected,
      onLazyLoad,
      onExpand,
      add_file,
      refresh,
      edit_settings,
      close_site,
      tree,
      rename_file,
      short_name
    }
  }
};
</script>
