import MimesDB from '@/mimes/mimesdb.js';

const DB = {};

for (const [mime, obj] of Object.entries(MimesDB)) {
  obj.extensions.forEach((ext) => {
    DB[ext] = mime;
  });
}

class Mime {
  static lookup(path) {
    var parts = path.split('.');
    var ext = parts[parts.length - 1].toLowerCase();
    return DB[ext] || "application/octet-stream";
  }
}

export default Mime;
