import {FileSystem, File} from '@/filesystem/base.js';

class LocalFile extends File {
  async load() {
    var fh = await this.filesystem.get_file_handle(this.path);
    var reader = await fh.getFile();
    this.text = await reader.text();
  }
}

class LocalFileSystem extends FileSystem {
  features = [];

  async find_config() {
    this.config_fh = await this.basedir.getFileHandle(this.spec.config_file);
  }

  async read_config() {
    var file = await this.config_fh.getFile();
    return await file.text();
  }

  async get_file_handle(path, create, create_dir) {
    var paths = path.split("/");
    var dh = this.basedir;
    var fh;
    create = create || false;
    create_dir = create_dir || false;

    for (var i=0; i < paths.length; i++) {
      if (i == paths.length - 1) {
        if (create_dir) {
          fh = await dh.getDirectoryHandle(paths[i], {create});
        } else {
          fh = await dh.getFileHandle(paths[i], {create});
        }
      } else {
        dh = await dh.getDirectoryHandle(paths[i], {create});
      }
    }

    return fh;
  }

  async open(path) {
    path = path.replace("<root>/", "");
    var file = new LocalFile(this.basedir, path, this);
    await file.load();
    file.parsed = {...this.spec.parse_content_file(file.text)};
    return file;
  }

  async rename(path, new_name) {
    path = path.replace("<root>/", "");
    let fh = await this.get_file_handle(path);
    // await fh.move(new_name);
    throw new Error('Rename Not Implement on Local Files');
  }

  async save(path, text, create) {
    text = text || '';
    path = path.replace("<root>/", "");

    var fh = await this.get_file_handle(path, create);
    var writer = await fh.createWritable();
    await writer.write(text);
    await writer.close();
  }

  async create_dir(path) {
    path = path.replace("<root>/", "");
    await this.get_file_handle(path, true, true);
  }

  async _list_dir(path, parent, file_type, create) {
    var dh;
    var parts = path.split('/');
    create = create || false;

    if (path == '<root>') {
      dh = parent.handle;
    } else {
      for (var i=0; i < parts.length; i++) {
        if (i == parts.length - 1) {
          dh = await parent.handle.getDirectoryHandle(parts[i], {create});
        } else {
          parent.handle = await parent.handle.getDirectoryHandle(parts[i], {create});
        }
      }
    }

    var dirs = [];
    var files = [];
    for await (const [key, value] of dh.entries()) {
      let base = `${parent.path}${path}/`;
      if (!path) {
        base = "";
      }

      if (value.kind == 'file' && file_type != 'dir') {
        if (this.is_file_type(key, file_type)) {
          files.push({
            label: key,
            id: `${base}${key}`,
            header: 'file',
            translator: parent.translator
          });
        }
      } else if (value.kind == 'directory') {
        dirs.push({
          label: key,
          id: `${base}${key}`,
          icon: 'mdi-folder',
          lazy: true,
          translator: parent.translator,
          parent: {...parent, path: `${base}`, handle: dh}
        });
      }
    }

    return [dirs, files];
  }
}

export default LocalFileSystem;
