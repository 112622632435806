<template>
  <q-dialog ref="dialogRef" @hide="onDialogCancel">
    <q-card class="q-dialog-plugin">
      <q-toolbar>
        <q-toolbar-title>
          New <span v-if="fileType == 'file'">File</span><span v-else>Folder</span>
          <strong> @ {{ node.label }}</strong>
        </q-toolbar-title>
        <q-btn color="negative" flat icon="mdi-close" @click="onDialogCancel" />
      </q-toolbar>
      <q-card-section>
        <q-input outlined autofocus v-model="name" label="Name" @keyup.enter="onOKClick"/>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn color="primary" label="Create" @click="onOKClick" />
        <q-btn color="accent" label="Cancel" @click="onDialogCancel" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
<script>
import { ref } from 'vue';
import { useDialogPluginComponent } from 'quasar';

export default {
  props: ['node', 'fileType'],
  setup(props) {
    const { dialogRef, onDialogOK, onDialogCancel } = useDialogPluginComponent();
    var name = ref("");

    function onOKClick () {
      if (name.value) {
        onDialogOK({name: name.value, fileType: props.fileType});
      } else {
        onDialogCancel();
      }

    }

    return {dialogRef, onOKClick, onDialogCancel, name};
  }
};
</script>
