<template>
  <q-dialog ref="dialogRef" @hide="onDialogCancel" class="file-picker">
    <q-card class="q-dialog-plugin">
      <q-toolbar>
        <q-toolbar-title>
          Insert Image
        </q-toolbar-title>
        <q-btn color="negative" flat icon="mdi-close" @click="onDialogCancel" />
      </q-toolbar>
      <q-form @submit="onSubmit">
        <q-card-section>
          <div>
            <q-input outlined dense v-model="path" label="Image" readonly/>
          </div>
          <div>
            <q-input outlined dense v-model="desc" label="Description"/>
          </div>
          <div class="size" v-if="config.allow_size">
            <q-input outlined dense v-model="width" label="Width"/>
            <q-input outlined dense v-model="height" label="Height"/>
          </div>
          <div v-if="config.allow_tags">
            <fieldset>
              <legend>Insert</legend>
              <q-radio v-model="format" val="md" label="Markdown" />
              <q-radio v-model="format" val="img" label="Image Tag"/>
            </fieldset>
          </div>
        </q-card-section>
        <q-card-actions>
          <q-space/>
          <q-btn color="secondary" @click="onDialogCancel">Cancel</q-btn>
          <q-btn color="primary" type="submit">Insert</q-btn>
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>
</template>
<script>
import { ref } from 'vue';
import { useDialogPluginComponent, Dialog } from 'quasar';

export default {
  props: ['parent', 'file', 'filesystem'],
  emits: [...useDialogPluginComponent.emits],
  setup(props) {
    const { dialogRef, onDialogOK, onDialogCancel } = useDialogPluginComponent();
    let width = ref('');
    let height = ref('');
    let desc = ref('');
    let format = ref('md');

    var path = `${props.parent.id}/${props.file.name}`;
    var config = props.filesystem.config_spec.image_config;

    function onSubmit () {
      onDialogOK({
        format: format.value,
        desc: desc.value,
        width: width.value,
        height: height.value
      });
    }

    return {dialogRef, onDialogCancel, path, desc, format, width, height, config, onSubmit};
  }
}
</script>
<style scoped>
  .q-card__section > div {
    display: flex;
    padding: 3px 0;

    label {
      width: 100%;
      padding: 0 2px;
    }

    fieldset {
      width: 100%;
      border: 1px dashed rgba(0, 0, 0, 0.25);
      border-radius: 4px;

      legend {
        padding: 0 4px;
      }
    }
  }
</style>
