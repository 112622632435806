import { createApp } from "vue";
import { Quasar, Notify, Dialog } from 'quasar';
import { createPinia } from 'pinia';
import VueGtag from "vue-gtag";
import * as Sentry from "@sentry/vue";

import iconSet from 'quasar/icon-set/mdi-v7.js';
import '@quasar/extras/mdi-v7/mdi-v7.css';
import 'quasar/dist/quasar.prod.css';

import VMdEditor from '@kangc/v-md-editor/lib/codemirror-editor';
import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/codemirror-editor.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';
import enUS from '@kangc/v-md-editor/lib/lang/en-US';

// Resources for the codemirror editor
import Codemirror from 'codemirror';
// mode
import 'codemirror/mode/markdown/markdown';
import 'codemirror/mode/gfm/gfm';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/css/css';
import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/mode/vue/vue';
import 'codemirror/mode/yaml/yaml';
import 'codemirror/mode/yaml-frontmatter/yaml-frontmatter';
// // edit
import 'codemirror/addon/edit/closebrackets';
import 'codemirror/addon/edit/closetag';
import 'codemirror/addon/edit/matchbrackets';
// // placeholder
import 'codemirror/addon/display/placeholder';
// // active-line
import 'codemirror/addon/selection/active-line';
// // scrollbar
import 'codemirror/addon/scroll/simplescrollbars';
import 'codemirror/addon/scroll/simplescrollbars.css';
// // style
import 'codemirror/lib/codemirror.css';

import hljs from 'highlight.js';

import '@/fonts/blackops.css';
import router from "@/routing.js";
import App from "@/App.vue";
import { monkey_patch_date } from "@/utils/date.js";

monkey_patch_date();

VMdEditor.Codemirror = Codemirror;
VMdEditor.use(githubTheme, {Hljs: hljs});
VMdPreview.use(githubTheme, {Hljs: hljs});
VMdEditor.lang.use('en-US', enUS);

const pinia = createPinia();
const app = createApp(App);

if (import.meta.env.PROD) {
  Sentry.init({
    app,
    dsn: "https://867d4f72165955b9b78e36db6d7223de@o4504543432409088.ingest.us.sentry.io/4506877964779520",
    integrations: [],
  });
}

app.use(pinia);
app.use(Quasar, {
  plugins: {Notify, Dialog},
  iconSet: iconSet,
  config: {
    brand: {
      primary: '#00a352',
      secondary: '#ff9933',
      accent: '#0052a3',

      dark: '#1d1d1d',
      'dark-page': '#121212',

      positive: '#99ff99',
      negative: '#cf3333',
      info: '#31CCEC',
      warning: '#F2C037'
    }
  }
});
app.use(router);
app.use(VMdEditor);
app.use(VMdPreview);

if (import.meta.env.PROD) {
  app.use(VueGtag, {config: { id: "G-PJ36WRNCSS" }}, router);
}

app.mount("#app");
