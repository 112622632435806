<template>
  <q-layout view="hHh lpR fFf">
    <q-header elevated class="bg-primary text-white">
      <q-toolbar>
        <q-toolbar-title>
          <q-avatar>
            <img src="/icons/inverted.png">
          </q-avatar>
          <span class="black-ops">PubCrank Docs</span>
        </q-toolbar-title>
        <q-btn flat icon="mdi-home" href="/"></q-btn>
        <q-btn flat icon="mdi-pencil-box-outline" href="/editor/"></q-btn>
        <span>{{ version }}</span>
      </q-toolbar>
    </q-header>
    <q-drawer v-model="drawer_open" side="left" bordered>
      <q-list bordered separator>
        <q-item clickable v-ripple href="/docs/">
          <q-item-section>Overview</q-item-section>
        </q-item>
        <q-item clickable v-ripple  href="/docs/config">
          <q-item-section>Config File</q-item-section>
        </q-item>
        <q-item clickable v-ripple  href="/docs/config/examples">
          <q-item-section>Example Configs</q-item-section>
        </q-item>
        <q-item clickable v-ripple  href="/docs/support">
          <q-item-section>Support &amp; Feedback</q-item-section>
        </q-item>
        <q-item clickable v-ripple  href="/docs/pricing">
          <q-item-section>Pricing</q-item-section>
        </q-item>
      </q-list>
    </q-drawer>
    <q-page-container class="main-container">
      <v-md-preview :text="content" v-if="content"></v-md-preview>
      <div v-else>
        <h3 class="tc">Loading Doc</h3>
        <q-circular-progress
          indeterminate
          rounded
          size="50px"
          color="primary"
          class="q-ma-md"
        />
      </div>
    </q-page-container>
  </q-layout>
</template>
<script>
import { ref } from 'vue';
import { useRoute } from 'vue-router';

import axios from 'axios';
import fmatter from 'front-matter';
import VueMarkdownEditor, { xss } from '@kangc/v-md-editor';

import VERSION from '@/version.js';
import { capture_error } from '@/utils/logging.js';

export default {
  setup() {
    var drawer_open = true;
    var route = useRoute();
    var page = route.params.page || 'index';
    var content = ref('');

    if (typeof(page) !== "string") {
      page = page.join("/");
    }

    axios.get(`/docs-content/${page}.md`)
      .then((response) => {
        if (response.data.startsWith('<!DOCTYPE html>')) {
          content.value = "<h2>404 - Page Not Found</h2>";
          document.title = "404 - Page Not Found";
        } else {
          var parsed = fmatter(response.data);
          content.value = parsed.body;
          var title = parsed.attributes.title || 'Docs';
          title += ' - PubCrank';
          document.title = title;
        }
      })
      .catch((e) => {
        capture_error(e);
        alert('Error loading document');
      });

    return {content, drawer_open, version: VERSION};
  }
};
</script>
<style scoped>
img {
  height: 32px !important;
  width: 32px !important;
  padding-right: 3px;
}

h3.tc {
  margin-top: 30px;
}

.q-circular-progress {
  margin: 20px auto;
  display: block;
}

.content {
  padding: 5px 10px;
  max-width: 900px;
  font-size: 1.2rem;
}
</style>
