<template>
  <q-layout view="hHh lpR fFf">
    <q-page-container>
      <div class="main">
        <h1 class="black-ops">
          <img src="/icons/android-chrome-192x192.png">
          <span>PubCrank</span>
        </h1>
        <h2><em>Start Cranking Out Content</em></h2>
        <p>
          PubCrank is a CMS for your MarkDown based static site.
        </p>
        <q-btn class="main" color="primary" rounded href="/editor/">Start Editing</q-btn>
        <br><br>
        <q-btn color="accent" href="/docs/">Read the Docs</q-btn>
        <br><br>
        <hr>
        <br>
        <p>
          PubCrank is a static website editor that is designed for developers, content creators,
          and editors. It lets a developer setup a static website in generators like
          Astro, Eleventy (11ty), Hugo, and Jekyll with guardrails for creators
          and editors to change content without breaking the style and layouts of the site.
        </p>
      </div>
      <div class="quick">
        <hr>
        <h3>Get Started</h3>
        <p>
          Get started with our documentation
          <br><br>
          <q-btn href="/docs/" color="accent">documentation</q-btn>
        </p>
        <br><br>
      </div>
      <div class="tc">
        <strong>Version: {{ version }}</strong>
        <br><br>
      </div>
    </q-page-container>
  </q-layout>
</template>
<style scoped>
.main {
  text-align: center;
  margin: 20px auto;
  max-width: 600px;
  font-size: 1.4rem;

  .q-btn.main {
    font-size: 100%;
  }

  h1 {
    font-size: 3.5rem;
    line-height: normal;
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--q-primary);
    text-shadow: 3px 3px #000;
    -webkit-text-stroke: 1px black;

    img {
      width: 88px;
      padding-right: 10px;
    }
  }
}

code {
  color: var(--q-accent);
}

.quick {
  margin: 20px auto;
  max-width: 960px;
  font-size: 1.2rem;
}
</style>
<script>
import VERSION from '@/version.js';

export default {
  setup() {
    document.title = "PubCrank";

    return {version: VERSION};
  }
};
</script>
