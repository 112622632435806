<template>
  <v-md-editor
    v-model="file.parsed.content"
    @save="(text, html) => save_file(text)"
    @change="file_changed"
    @upload-image="handle_upload"
    left-toolbar="undo redo clear | h bold italic strikethrough quote | ul ol table hr | link code | insertImage insertUpload selectImage | save"
    :codemirror-config="config"
    :toolbar="toolbar"
    ref="editor"
  ></v-md-editor>
</template>
<script>
import { ref } from 'vue';
import { Dialog } from 'quasar';
import debounce from 'debounce';

import FilePicker from '@/dialogs/file-picker.vue';
import InsertImage from '@/dialogs/insert-image.vue';
import { error_alert } from '@/utils/logging.js';

export default {
  props: ['file'],
  emits: ['changed', 'save'],
  setup(props, { emit }) {
    var editor = ref(null);
    var config = {
      tabSize: 2,
      lineNumbers: true,
      styleActiveLine: true,
      mode: 'markdown',
      lineWrapping: 'wrap',
      highlightFormatting: true,
      inputStyle: 'contenteditable',
      spellcheck: true,
      smartIndent: true
    };

    var initial = props.file.parsed.content;
    var file_changed = debounce((value) => {
      if (value != initial) {
        emit('changed');
      }
    }, 200);

    function save_file (text, html) {
      emit('save', text);
    }

    function handle_upload (file, doc, cur) {
      Dialog.create({
        component: FilePicker,
        componentProps: {
          filesystem: props.file.filesystem,
          file_type: 'dir'
        }
      })
        .onOk((picked) => {
          insert_image(picked, file, doc, cur)
            .then(() =>  {})
            .catch(error_alert("Error uploading file."));
        })
        .onCancel(() => {});
    }

    async function insert_image (parent, file, doc, cur) {
      if (file.lastModified) {
        await props.file.filesystem.add({name: file.name, fileType: "file", parent, body: file});
      }

      Dialog.create({
        component: InsertImage,
        componentProps: {filesystem: props.file.filesystem, parent, file}
      })
        .onOk((data) => {
          data.path = parent.translator(`${parent.id}/${file.name}`);

          if (cur) {
            if (cur.anchor) {
              doc.setSelection(cur.anchor, cur.head);
            } else {
              doc.setCursor({line: cur.line, ch: cur.ch});
            }
          }

          editor.value.insert(() => {
            let content;

            try {
              content = props.file.filesystem.config_spec.image_code(data);
            } catch (e) {
              error_alert("Error with image template.")(e);
            }

            return {text: content, selected: null};
          });
        })
        .onCancel(() => {});
    }

    var toolbar = {
      insertImage: {
        icon: 'mdi mdi-image-outline',
        title: 'Image',
        action(instance) {
          instance.insert(() => {
            return {text: "![](https://)", selected: "https://"};
          });
        }
      },
      insertUpload: {
        icon: 'mdi mdi-image-plus-outline',
        title: 'Upload Image',
        action(instance) {
          var input = document.createElement('input');
          input.type = 'file';
          input.onchange = e => {
            let doc = instance.codemirrorInstance.doc;
            let cur = doc.getCursor();
            let sel = doc.listSelections()[0];
            if (sel.anchor.ch != sel.head.ch || sel.anchor.line != sel.head.line) {
              cur = sel;
            }

            handle_upload(e.target.files[0], doc, cur);
          }

          input.click();
        }
      },
      selectImage: {
        icon: 'mdi mdi-image-search-outline',
        title: 'Select Image',
        action(instance) {
          let doc = instance.codemirrorInstance.doc;
          let cur = doc.getCursor();
          let sel = doc.listSelections()[0];
          if (sel.anchor.ch != sel.head.ch || sel.anchor.line != sel.head.line) {
            cur = sel;
          }

          Dialog.create({
            component: FilePicker,
            componentProps: {
              filesystem: props.file.filesystem,
              file_type: 'image',
              choose_parent: true
            }
          })
            .onOk((picked) => {
              insert_image(picked.parent, {name: picked.filename}, doc, cur)
                .then(() =>  {})
                .catch(error_alert("Error inserting image."));
            })
            .onCancel(() => {});
        }
      }
    }

    return {config, file_changed, save_file, handle_upload, editor, toolbar};
  }
};
</script>
