<template>
  <q-dialog ref="dialogRef" @hide="onDialogCancel">
    <q-card class="q-dialog-plugin">
      <q-toolbar>
        <q-toolbar-title>
          Upload File
          <strong> @ {{ node.label }}</strong>
        </q-toolbar-title>
        <q-btn color="negative" flat icon="mdi-close" @click="onDialogCancel" />
      </q-toolbar>
      <q-card-section>
        <q-file outlined v-model="file" :accept="mimetype" label="Choose File" @keyup.enter="onOKClick">
          <template v-slot:prepend>
            <q-icon name="mdi-file-find-outline" />
          </template>
        </q-file>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn color="primary" label="Upload" @click="onOKClick" />
        <q-btn color="accent" label="Cancel" @click="onDialogCancel" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
<script>
import { ref } from 'vue';
import { useDialogPluginComponent } from 'quasar';

export default {
  props: ['node', 'accept'],
  setup(props) {
    const { dialogRef, onDialogOK, onDialogCancel } = useDialogPluginComponent();
    var file = ref(null);
    var mimetype = ref(null);
    if (props.accept == "image") {
      mimetype.value = "image/*"
    }

    function onOKClick () {
      if (file.value) {
        onDialogOK(file.value);
      } else {
        onDialogCancel();
      }

    }

    return {dialogRef, onOKClick, onDialogCancel, file, mimetype};
  }
};
</script>
