export default {
  "application/xhtml+xml": {
    "extensions": [
      "xhtml",
      "xht",
      "xhtm"
    ]
  },
  "text/html": {
    "extensions": [
      "html",
      "htm",
      "shtml",
      "shtm"
    ]
  },
  "image/apng": {
    "extensions": [
      "apng"
    ]
  },
  "application/wasm": {
    "extensions": [
      "wasm"
    ]
  },
  "audio/mp3": {
    "extensions": [
      "mp3"
    ]
  },
  "audio/wav": {
    "extensions": [
      "wav"
    ]
  },
  "audio/x-flac": {
    "extensions": [
      "flac"
    ]
  },
  "audio/x-m4a": {
    "extensions": [
      "m4a"
    ]
  },
  "application/javascript": {
    "charset": "UTF-8",
    "extensions": [
      "js",
      "mjs"
    ]
  },
  "video/x-m4v": {
    "extensions": [
      "m4v"
    ]
  },
  "video/ogg": {
    "extensions": [
      "ogv",
      "ogm"
    ]
  },
  "application/epub+zip": {
    "extensions": [
      "epub"
    ]
  },
  "application/pdf": {
    "extensions": [
      "pdf"
    ]
  },
  "video/mpeg": {
    "extensions": [
      "mpeg",
      "mpg",
      "mpe",
      "m1v",
      "m2v"
    ]
  },
  "audio/mpeg": {
    "extensions": [
      "mpga",
      "mp2",
      "mp2a",
      "mp3",
      "m2a",
      "m3a"
    ]
  },
  "font/otf": {
    "extensions": [
      "otf"
    ]
  },
  "font/ttf": {
    "extensions": [
      "ttf"
    ]
  },
  "font/woff": {
    "extensions": [
      "woff"
    ]
  },
  "font/woff2": {
    "extensions": [
      "woff2"
    ]
  },
  "application/atom+xml": {
    "extensions": [
      "atom"
    ]
  },
  "application/ecmascript": {
    "extensions": [
      "ecma",
      "es"
    ]
  },
  "application/geo+json": {
    "extensions": [
      "geojson"
    ]
  },
  "application/json": {
    "charset": "UTF-8",
    "extensions": [
      "json",
      "map"
    ]
  },
  "application/jsonml+json": {
    "extensions": [
      "jsonml"
    ]
  },
  "application/ld+json": {
    "extensions": [
      "jsonld"
    ]
  },
  "application/manifest+json": {
    "charset": "UTF-8",
    "extensions": [
      "webmanifest"
    ]
  },
  "application/ogg": {
    "extensions": [
      "ogx"
    ]
  },
  "application/rss+xml": {
    "extensions": [
      "rss"
    ]
  },
  "application/x-web-app-manifest+json": {
    "extensions": [
      "webapp"
    ]
  },
  "application/xml": {
    "extensions": [
      "xml",
      "xsl",
      "xsd",
      "rng"
    ]
  },
  "audio/mp4": {
    "extensions": [
      "m4a",
      "mp4a"
    ]
  },
  "audio/ogg": {
    "extensions": [
      "oga",
      "ogg",
      "spx"
    ]
  },
  "audio/webm": {
    "extensions": [
      "weba"
    ]
  },
  "audio/x-wav": {
    "extensions": [
      "wav"
    ]
  },
  "image/bmp": {
    "extensions": [
      "bmp"
    ]
  },
  "image/gif": {
    "extensions": [
      "gif"
    ]
  },
  "image/jpeg": {
    "extensions": [
      "jpeg",
      "jpg",
      "jpe"
    ]
  },
  "image/png": {
    "extensions": [
      "png"
    ]
  },
  "image/svg+xml": {
    "extensions": [
      "svg",
      "svgz"
    ]
  },
  "image/vnd.microsoft.icon": {
    "extensions": [
      "ico"
    ]
  },
  "image/webp": {
    "extensions": [
      "webp"
    ]
  },
  "image/x-icon": {
    "extensions": [
      "ico"
    ]
  },
  "image/x-xbitmap": {
    "extensions": [
      "xbm"
    ]
  },
  "text/cache-manifest": {
    "extensions": [
      "appcache",
      "manifest"
    ]
  },
  "text/coffeescript": {
    "extensions": [
      "coffee",
      "litcoffee"
    ]
  },
  "text/css": {
    "charset": "UTF-8",
    "extensions": [
      "css"
    ]
  },
  "text/jade": {
    "extensions": [
      "jade"
    ]
  },
  "text/jsx": {
    "extensions": [
      "jsx"
    ]
  },
  "text/less": {
    "extensions": [
      "less"
    ]
  },
  "text/markdown": {
    "extensions": [
      "markdown",
      "md"
    ]
  },
  "text/mathml": {
    "extensions": [
      "mml"
    ]
  },
  "text/n3": {
    "extensions": [
      "n3"
    ]
  },
  "text/plain": {
    "extensions": [
      "txt",
      "text",
      "conf",
      "def",
      "list",
      "log",
      "in",
      "ini"
    ]
  },
  "text/prs.lines.tag": {
    "extensions": [
      "dsc"
    ]
  },
  "text/richtext": {
    "extensions": [
      "rtx"
    ]
  },
  "text/sgml": {
    "extensions": [
      "sgml",
      "sgm"
    ]
  },
  "text/shex": {
    "extensions": [
      "shex"
    ]
  },
  "text/slim": {
    "extensions": [
      "slim",
      "slm"
    ]
  },
  "text/stylus": {
    "extensions": [
      "stylus",
      "styl"
    ]
  },
  "text/troff": {
    "extensions": [
      "t",
      "tr",
      "roff",
      "man",
      "me",
      "ms"
    ]
  },
  "text/turtle": {
    "charset": "UTF-8",
    "extensions": [
      "ttl"
    ]
  },
  "text/uri-list": {
    "extensions": [
      "uri",
      "uris",
      "urls"
    ]
  },
  "text/vnd.curl": {
    "extensions": [
      "curl"
    ]
  },
  "text/vnd.curl.dcurl": {
    "extensions": [
      "dcurl"
    ]
  },
  "text/vnd.curl.mcurl": {
    "extensions": [
      "mcurl"
    ]
  },
  "text/vnd.curl.scurl": {
    "extensions": [
      "scurl"
    ]
  },
  "text/vnd.dvb.subtitle": {
    "extensions": [
      "sub"
    ]
  },
  "text/vnd.fly": {
    "extensions": [
      "fly"
    ]
  },
  "text/vnd.fmi.flexstor": {
    "extensions": [
      "flx"
    ]
  },
  "text/vnd.graphviz": {
    "extensions": [
      "gv"
    ]
  },
  "text/vnd.in3d.3dml": {
    "extensions": [
      "3dml"
    ]
  },
  "text/vnd.in3d.spot": {
    "extensions": [
      "spot"
    ]
  },
  "text/vnd.wap.wml": {
    "extensions": [
      "wml"
    ]
  },
  "text/vnd.wap.wmlscript": {
    "extensions": [
      "wmls"
    ]
  },
  "text/vtt": {
    "charset": "UTF-8",
    "extensions": [
      "vtt"
    ]
  },
  "text/x-asm": {
    "extensions": [
      "s",
      "asm"
    ]
  },
  "text/x-c": {
    "extensions": [
      "c",
      "cc",
      "cxx",
      "cpp",
      "h",
      "hh",
      "dic"
    ]
  },
  "text/x-component": {
    "extensions": [
      "htc"
    ]
  },
  "text/x-fortran": {
    "extensions": [
      "f",
      "for",
      "f77",
      "f90"
    ]
  },
  "text/x-handlebars-template": {
    "extensions": [
      "hbs"
    ]
  },
  "text/x-java-source": {
    "extensions": [
      "java"
    ]
  },
  "text/x-lua": {
    "extensions": [
      "lua"
    ]
  },
  "text/x-markdown": {
    "extensions": [
      "mkd"
    ]
  },
  "text/x-nfo": {
    "extensions": [
      "nfo"
    ]
  },
  "text/x-opml": {
    "extensions": [
      "opml"
    ]
  },
  "text/x-org": {
    "extensions": [
      "org"
    ]
  },
  "text/x-pascal": {
    "extensions": [
      "p",
      "pas"
    ]
  },
  "text/x-processing": {
    "extensions": [
      "pde"
    ]
  },
  "text/x-sass": {
    "extensions": [
      "sass"
    ]
  },
  "text/x-scss": {
    "extensions": [
      "scss"
    ]
  },
  "text/x-setext": {
    "extensions": [
      "etx"
    ]
  },
  "text/x-sfv": {
    "extensions": [
      "sfv"
    ]
  },
  "text/x-suse-ymp": {
    "extensions": [
      "ymp"
    ]
  },
  "text/x-uuencode": {
    "extensions": [
      "uu"
    ]
  },
  "text/xml": {
    "extensions": [
      "xml"
    ]
  },
  "text/yaml": {
    "extensions": [
      "yaml",
      "yml"
    ]
  },
  "video/mp4": {
    "extensions": [
      "mp4",
      "mp4v",
      "mpg4"
    ]
  },
  "video/webm": {
    "extensions": [
      "webm"
    ]
  }
}
