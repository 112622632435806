<template>
  <q-dialog ref="dialogRef" @hide="onDialogCancel" class="file-picker">
    <q-card class="q-dialog-plugin">
      <q-toolbar>
        <q-toolbar-title>
          Choose A
          <span v-if="file_type == 'dir'">Folder</span>
          <span v-else>File</span>
        </q-toolbar-title>
        <q-btn color="negative" flat icon="mdi-close" @click="onDialogCancel" />
      </q-toolbar>
      <q-card-section>
        <q-tree
          ref="tree"
          :nodes="filetree"
          node-key="id"
          @lazy-load="onLazyLoad"
          v-model:expanded="expanded"
        >
          <template v-slot:header-file="prop">
            <q-btn
              flat
              no-wrap
              icon="mdi-image"
              align="left"
              size="md"
              padding="xs"
              class="img"
              @click.stop.prevent="onOKClick(prop.node)"
            >
              {{ prop.node.label }}
            </q-btn>
          </template>
          <template v-slot:default-header="prop">
            <q-icon :name="prop.node.icon" class="q-mr-sm" />
            <div>{{ prop.node.label }}</div>
            <q-space/>
            <q-btn v-if="file_type == 'dir'"
              flat
              dense
              icon="mdi-checkbox-marked-circle-outline"
              title="Upload Here"
              @click.stop.prevent="chooseDir(prop.node)"
            ></q-btn>
            <q-btn dense icon="mdi-dots-vertical" flat @click.stop="(e) => {}">
              <q-menu>
                <q-list dense bordered separator>
                  <q-item v-if="file_type != 'dir'" dense clickable v-close-popup @click="(e) => add_file(e, prop.node, 'file')">
                    <q-item-section avatar>
                      <q-icon name="mdi-file-document-plus-outline" />
                    </q-item-section>
                    <q-item-section>
                      Upload File
                    </q-item-section>
                  </q-item>
                  <q-item dense clickable v-close-popup @click="(e) => add_file(e, prop.node, 'dir')">
                    <q-item-section avatar>
                      <q-icon name="mdi-folder-plus-outline" />
                    </q-item-section>
                    <q-item-section>
                      Add Folder
                    </q-item-section>
                  </q-item>
                  <q-item clickable v-close-popup @click="(e) => refresh(e, prop.node)">
                    <q-item-section avatar>
                      <q-icon name="mdi-refresh" />
                    </q-item-section>
                    <q-item-section>
                      Refresh
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-menu>
            </q-btn>
          </template>
        </q-tree>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>
<script>
import { ref, nextTick, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useDialogPluginComponent, Dialog } from 'quasar';

import useMainStore from '@/main_store.js';
import AddFile from '@/dialogs/add-file.vue';
import UploadFile from '@/dialogs/upload-file.vue';

export default {
  props: ['filesystem', 'file_type', 'choose_parent'],
  emits: [...useDialogPluginComponent.emits],
  setup(props) {
    const { dialogRef, onDialogOK, onDialogCancel } = useDialogPluginComponent();
    var filetree = ref([]);
    var expanded = ref([]);
    var tree = ref(null);
    var { fs } = storeToRefs(useMainStore());

    watch(tree, (newRef, oldRef) => {
      if (newRef && oldRef === null) {
        props.filesystem.list_static_dir(null, null, props.file_type)
          .then((files) => {
            filetree.value = files;
            nextTick(() => {
              files.forEach((f) => {
                tree.value.setExpanded(f.id, true);
              });
            });
          })
          .catch((e) => {
            console.error(e);
          });
      }
    });

    function onOKClick (node) {
      if (props.choose_parent) {
        let key = node.id.split('/').slice(0, -1).join("/");
        let parent = tree.value.getNodeByKey(key);
        onDialogOK({filename: node.label, parent});
      } else {
        onDialogOK(node.translator(node.id));
      }
    }

    function chooseDir (node) {
      onDialogOK(node);
    }

    function onLazyLoad ({ node, key, done, fail }) {
      props.filesystem.list_static_dir(node.label, node.parent, props.file_type)
        .then((files) => {
          done(files);
        })
        .catch((e) => {
          console.error(e);
          fail();
        });
    }

    function add_file(e, node, fileType) {
      if (fileType == 'dir') {
        Dialog.create({
          component: AddFile,
          componentProps: {node, fileType}
        })
          .onOk((data) => {
            fs.value.add({...data, parent: node})
              .then(() => {
                refresh(e, node);
              })
              .catch((e) =>  {
                console.error(e);
                alert(`Error creating ${data.name}`);
              });
          })
          .onCancel(() => {});
      } else {
        Dialog.create({
          component: UploadFile,
          componentProps: {node, accept: props.file_type}
        })
          .onOk((data) => {
            fs.value.add({name: data.name, fileType: "file", parent: node, body: data})
              .then(() => {
                if (props.choose_parent) {
                  onDialogOK({filename: data.name, parent: node});
                } else {
                  onDialogOK(node.translator(`${node.id}/${data.name}`));
                }
              })
              .catch((e) =>  {
                console.error(e);
                alert(`Error uploading ${data.name}`);
              });
          })
          .onCancel(() => {});
      }
    }

    function refresh(e, node) {
      tree.value.setExpanded(node.id, false);
      delete node.children;

      nextTick(() => {
        tree.value.setExpanded(node.id, true);
      });
    }

    return {
      tree,
      dialogRef,
      onOKClick,
      onDialogCancel,
      filetree,
      onLazyLoad,
      expanded,
      refresh,
      add_file,
      chooseDir
    };
  }
}
</script>
<style scoped>
.file-picker {
  .q-card__section {
    padding-top: 0;
  }

  .q-tree__node-header {
    padding: 0;
  }

  .q-btn {
    padding: 0 !important;
  }

  .q-btn {
    text-transform: none !important;
  }
}

.q-tree button.file {
  text-transform: none;
  width: 100%;
}

.q-item__section--avatar {
  min-width: unset;
}
</style>
