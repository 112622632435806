<template>
  <div class="input" v-if="field.choices">
    <q-select
      outlined
      dense
      v-model="ivalue"
      :options="file.filesystem.config_obj.choices[field.choices]"
      :label="titleCase(label)" />
  </div>
  <div class="input" v-else-if="itype == 'image'">
    <q-input
      outlined
      dense
      :label="titleCase(label)"
      v-model="ivalue"
      v-bind="attrs"
    >
      <template v-slot:append>
        <q-btn
          flat
          icon="mdi-image-search"
          color="primary"
          @click="choose_image()"/>
      </template>
    </q-input>
  </div>
  <div class="input" v-else-if="itype == 'checkbox'">
    <q-checkbox dense :label="titleCase(label)" v-model="ivalue" v-bind="attrs"/>
  </div>
  <div class="input" v-else-if="itype == 'readonly'">
    <q-input
      outlined
      dense
      readonly
      v-model="ivalue"
      :label="titleCase(label) + ' (read only)'"
      v-bind="attrs"/>
  </div>
  <div class="input" v-else-if="itype == 'yaml'">
    <yaml-input :initial="ivalue" :label="titleCase(label)" @change="update_yaml"></yaml-input>
  </div>
  <div class="input" v-else>
    <q-input
      outlined
      dense
      :type="itype"
      :label="titleCase(label)"
      v-model="ivalue"
      v-bind="attrs"/>
  </div>
</template>
<script>
import { computed } from 'vue';
import { Dialog } from 'quasar';

import { titleCase } from '@/utils/str.js';
import FilePicker from '@/dialogs/file-picker.vue';
import YamlInput from '@/editor/input_yaml.vue';

export default {
  components: {YamlInput},
  props: ['label', 'track', 'itrack', 'file', 'field'],
  emits: ['changed'],
  setup(props, { emit }) {
    var attrs = computed(() => {
      props.field.attrs || {};
    });

    var itype = computed(() => {
      let ret = props.field.type || 'text';
      ret = ret.toLowerCase();

      if (ret.startsWith('list[')) {
        ret = ret.replace('list[', '');
        ret = ret.replace(']', '');
      }

      if (ret == "datetime") {
        ret = 'datetime-local';
      } else if (ret == "boolean") {
        ret = 'checkbox';
      }
      return ret;
    });

    var ivalue = computed({
      get() {
        if (itype.value == 'readonly') {
          return props.field.value;
        }

        if (props.field.default !== undefined) {
          if (props.itrack !== undefined) {
            if (props.file.parsed.data[props.track][props.itrack] === undefined) {
              props.file.parsed.data[props.track][props.itrack] = props.field.default;
            }
          } else if (props.file.parsed.data[props.track] === undefined) {
            props.file.parsed.data[props.track] = props.field.default;
          }
        }

        let ret = props.file.parsed.data[props.track];
        if (props.itrack !== undefined) {
          ret = ret[props.itrack];
        }

        if (ret) {
          if (itype.value == 'datetime-local') {
            ret = new Date(ret).toDatetimeLocal();
          } else if (itype.value == 'number') {
            ret = ret.toString();
          }
        }

        return ret;
      },
      set(newValue) {
        let setval = newValue;
        emit('changed', newValue);

        if (setval) {
          if (itype.value == 'datetime-local') {
            setval = new Date(setval);
          } else if (itype.value == 'number') {
            setval = parseFloat(setval);
          }
        } else {
          if (itype.value == 'datetime-local') {
            setval = null;
          } else if (itype.value == 'number') {
            setval = null;
          }
        }

        if (props.itrack !== undefined) {
          props.file.parsed.data[props.track][props.itrack] = setval;
        } else {
          props.file.parsed.data[props.track] = setval;
        }
      }
    });

    function choose_image () {
      Dialog.create({
        component: FilePicker,
        componentProps: {
          filesystem: props.file.filesystem,
          file_type: 'image'
        }
      })
        .onOk((picked) => {
          ivalue.value = picked;
        })
        .onCancel(() => {});
    }

    function update_yaml(payload) {
      if (payload === undefined) {
        payload = null;
      }

      ivalue.value = payload;
    }

    return {titleCase, attrs, ivalue, itype, choose_image, update_yaml};
  }
};
</script>
<style scoped>
.input {
  flex: 1;
  padding: 0 7px 0 0;
}
</style>
