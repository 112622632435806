<template>
  <div @keydown.ctrl.s="save_file" @keydown.meta.s="save_file">
    <div v-for="(field, key) in fields" class="front-field" v-if="fields">
      <div :class="input_type(field)" v-if="input_type(field) != 'list'">
        <front-input
          :label="key"
          :track="key"
          :file="file"
          :field="field"
          @changed="file_changed"
        >
        </front-input>
      </div>
      <div :class="input_type(field)" v-else>
        <div class="buttons">
          <q-btn
            round
            color="primary"
            icon="mdi-plus"
            title="add"
            size="sm"
            @click="add_row(file.parsed.data[key])"/>
          <q-btn
            round
            color="warning"
            icon="mdi-nuke"
            title="clear all"
            size="sm"
            @click="clear_all(file.parsed.data[key])"/>
        </div>
        <h4>{{ key }}</h4>
        <div class="rounded-borders">
          <span v-for="(l, idx) in file.parsed.data[key]"
            draggable="true"
            @dragover="(evt) => dragOver(evt, key, idx)"
            @dragend="(evt) => dragEnd(evt, key, idx)"
            @dragstart="(evt) => dragStart(evt, key, idx)"
          >
            <q-icon name="mdi-drag-horizontal-variant"/>
            <front-input
              :file="file"
              :track="key"
              :itrack="idx"
              :field="field"
              :label="sub_type(field)"
              @changed="file_changed"
            >
            </front-input>
            <div>
              <q-btn
                round
                flat
                color="negative"
                icon="mdi-close"
                title="remove"
                size="sm"
                @click="rm_row(file.parsed.data[key], idx)"/>
            </div>
          </span>
        </div>
      </div>
    </div>
    <div v-else>
      <h4 class="empty">No Data Fields Found</h4>
    </div>
  </div>
</template>
<script>
import { computed, onMounted } from 'vue';
import debounce from 'debounce';

import FrontInput from '@/editor/fmatter_input.vue';

export default {
  props: ['file'],
  emits: ['changed'],
  components: {FrontInput},
  setup(props, { emit }) {
    var fields = computed(() => {
      var ret = props.file.get_fields();
      for (const [key, field] of Object.entries(ret)) {
        if (input_type(field) == 'list') {
          if (!props.file.parsed.data[key] || !props.file.parsed.data[key].push) {
            props.file.parsed.data[key] = [];
          }
        }
      }

      return ret;
    });

    function input_type (field) {
      var f = field.type || 'text';
      f = f.toLowerCase();
      if (f.startsWith('list[')) {
        return 'list';
      }
    }

    function sub_type (field) {
      var f = field.type.toLowerCase();
      f = f.replace('list[', '');
      f = f.replace(']', '');
      return f;
    }

    async function save_file() {
      event.preventDefault();
      await props.file.save();
    }

    function add_row(list) {
      list.unshift(null);
    }

    function rm_row(list, index) {
      list.splice(index, 1);
    }

    function clear_all(list) {
      var loop = list.length - 1;
      for (var i=0; i < loop; i++) {
        list.pop();
      }

      list[0] = null;
    }

    var file_changed = debounce((value) => {
      emit('changed');
    }, 200);

    var dragging = null;

    function dragStart(evt, key, old_index) {
      dragging = {key, old_index};
    }

    function dragOver(evt, key, new_index) {
      evt.preventDefault();

      if (dragging && new_index != dragging.old_index && key == dragging.key) {
        let iarray = [...props.file.parsed.data[key]];
        let item = iarray.splice(dragging.old_index, 1)[0];
        iarray.splice(new_index, 0, item);
        iarray.forEach((val, i) => {
          props.file.parsed.data[key][i] = val;
        });

        dragging = {key, old_index: new_index};
        file_changed();
      }
    }

    function dragEnd(evt, key, new_index) {
      dragging = null;
    }

    return {fields, input_type, sub_type, save_file, add_row, rm_row, clear_all, file_changed, dragEnd, dragOver, dragStart};
  }
}
</script>
<style scoped>
.file-buttons {
  margin: 0 10px 15px 10px;
}

h4.empty {
  margin: 10px;
}

.front-field {
  margin: 0 5px 8px 5px;

  & > div {
    max-width: 600px;
  }

  .list > div.rounded-borders {
    max-height: 400px;
    overflow: auto;
    padding: 5px;
    border: 1px dotted rgba(0,0,0,.70);
  }

  .list > div > span {
    margin: 0 0 3px 0;
    display: flex;
    align-items: center;
  }

  .list > div.buttons {
    float: right;

    .q-btn {
      margin: 0 3px;
    }
  }

  .list .q-icon {
    padding: 0 10px 0 5px;
    cursor: move;
  }

  .list h4 {
    margin: 15px 0 5px 0;
    font-size: 1.4rem;
    text-transform: capitalize;
  }
}
</style>
